import "./polyfills";
import searchForm from './searchForm';
import tabs from './tabs';
import {initFormValidate, StepsForm, initAntiSpam, addSourcePageToForm, submiter, saveFormInfo, addMarketingInfo} from './forms';
import trunk from './trunk.ts';
import fancy from './fancy.ts';

window.addEventListener('DOMContentLoaded', function(){

	trunk();
	// matrikaReachGoals();
	tabs('examples__nav', 'examples__tabs', {scroll:0});
	searchForm('.header__search-form', '.header__search-icon', '[data-component="header-menu"]', '.header__search-icon-close');
	// cardClick('[data-component="cards"]');
	// accordion('[data-component="accordion"]');
	// startCounters('[data-element="field_number"]');
	// productPrice();

	// productOrderSum();
 
// Валидация форм
	if(document.querySelector('[data-component="steps_form"]')){
		const stepsForm = new StepsForm({
			formSelector: '[data-component="steps_form"]',
			stepsSelector: '.fields',
			nextBtnSelector: '#nextBtn',
			prevBtnSelector: '#prevBtn'
		});
		stepsForm.init();
	}

	initFormValidate('.form', ['form_search', 'm-form_search', 'filter', 'registrate', 'auth', 'forget']);
	initAntiSpam('.form', ['form_search', 'm-form_search', 'filter', 'registrate', 'auth', 'forget']);
	addSourcePageToForm('.sourcePage');
	saveFormInfo();
	addMarketingInfo();


	// Fancy
	const selectors = [
		{trigger: 'triggerOrderForm', form: 'default_form', header: 'Заказать'},
		{trigger: 'triggerCallbackForm', form: 'callback_form', header: 'Заказать обратный звонок', desc: 'Заполните данные формы и мы свяжемся с вами в ближайшее время'},
		{trigger: 'triggerConsultForm', form: 'default_form', header: 'Консультация', desc: 'Заполните данные формы и мы свяжемся с вами в ближайшее время'},
	];
	fancy(selectors);

});