import { $$ } from './utils';
const fancy = (selectors) => {
    selectors.forEach(item => {
        $$(`.${item.trigger}`).forEach((trigger) => {
            const form = document.querySelector(`#${item.form}`);
            const header = form.querySelector('.modal-form__title__header');
            const desc = form.querySelector('.modal-form__title__desc');
            trigger.on('click', () => {
                // Изменить модальное окно 
                header ? header.innerHTML = item.header : '';
                if (desc) {
                    if (item.desc) {
                        desc.classList.remove('hide');
                        desc.innerHTML = item.desc;
                    }
                    else {
                        desc.classList.add('hide');
                    }
                }
                // Показать модальное коно с формой
                $.fancybox.open({
                    src: `#${item.form}`,
                    type: 'inline'
                });
            });
        });
    });
};
export default fancy;
