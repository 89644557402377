var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default function submiter(form) {
    const ajaxSend = (url, formData) => __awaiter(this, void 0, void 0, function* () {
        const fetchResp = yield fetch(url, {
            method: 'POST',
            body: formData
        });
        if (!fetchResp.ok) {
            throw new Error(`Ошибка по адресу ${url}, статус ошибки ${fetchResp.status}`);
        }
        return yield fetchResp.text();
    });
    const formData = new FormData(form);
    ajaxSend('/webforms/secureSend/', formData)
        .then(response => {
        processing(response);
        form.reset();
        // window.ym(56313895,'reachGoal', 'formSent');
    })
        .catch(err => console.error(err));
    const processing = (pageSourceHtml) => {
        // form?.querySelector('.modal-form__title').classList.add('hide');
        form.parentElement.previousElementSibling.classList.add('hide');
        form.parentElement.innerHTML = `
            <div class="col-24 column">
                <h2>Сообщение отправлено</h2>
                <p>В ближайшее время менеджер ответит на ваше обращение</p>
            </div>
        `;
        // pageSourceHtml.indexOf('<div class="{$col-medium} justify-center column formPostedMessege">')
    };
}
;
